import {
    fetchingDataWithAxiosMiddleware,
    set_backendErrors,
    set_countries,
    set_forms,
    set_loading,
    set_requests,
    set_response_notification,
    set_space_info,
    setAffiliateIncomes,
    setAffiliateLinks,
    setDebuggingInfo,
    setServerIsDown,
    setTestingMode,
} from "./commonAction";
import {get_vendors} from "./vendor";
import apiService from "../../serviceUtils";
import {changeResponseDate, queryParamsForCommonFilter} from "../../config";

export const get_forms = (query) => async (dispatch) => {
    try {
        const params = query
            ? query
            : JSON.parse(sessionStorage.getItem("filterParams")).forms;
        dispatch(set_loading({type: "forms", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.get_forms()}?${queryParamsForCommonFilter(params)}`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                dispatch(set_forms(changeResponseDate(data, "forms")));
            }
            dispatch(set_loading({type: "forms", value: false}));
        }
    } catch (error) {
        dispatch(set_loading({type: "forms", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const getAffiliateLinks = () => async (dispatch) => {
    try {
        dispatch(set_loading({type: "affiliateLinks", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.get_affiliates_links()}`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                dispatch(setAffiliateLinks(changeResponseDate(data, "affiliateLinks")));
                dispatch(set_loading({type: "affiliateLinks", value: false}));
            }
        }
    } catch (error) {
        dispatch(set_loading({type: "affiliateLinks", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const generateAffiliateLinks = (data) => async (dispatch) => {
    const formData = new FormData();
    data && formData.append("rate", data);
    dispatch(set_loading({type: "affiliateLinks", value: true}));
    try {
        const response = await fetchingDataWithAxiosMiddleware(
            "POST",
            `${apiService.get_affiliates_links()}`,
            formData
        );
        if (response.status) {
            dispatch(getAffiliateLinks());
            dispatch(set_response_notification({type: "success", text: "Success"}));
        }
    } catch (error) {
        dispatch(set_loading({type: "affiliateLinks", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const deleteAffiliateLinks = (data) => async (dispatch) => {
    try {
        dispatch(set_loading({type: "affiliateLinks", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "DELETE",
            `${apiService.get_affiliates_links()}/${data}`
        );
        if (response?.status) {
            dispatch(set_response_notification({type: "success", text: "Success"}));
            dispatch(getAffiliateLinks());
        }
    } catch (error) {
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
        dispatch(set_loading({type: "affiliateLinks", value: false}));
    }
};
export const getAffiliateIncomes = () => async (dispatch) => {
    try {
        dispatch(set_loading({type: "affiliateIncomes", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.get_affiliates_incomes()}?detailed=true`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                dispatch(
                    setAffiliateIncomes(changeResponseDate(data, "affiliateIncomes"))
                );
                dispatch(set_loading({type: "affiliateIncomes", value: false}));
            }
        }
    } catch (error) {
        dispatch(set_loading({type: "affiliateIncomes", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};

export const get_space_info = () => async (dispatch) => {
    try {
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.spaces_info()}`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                dispatch(set_space_info(data));
            }
        }
    } catch (error) {
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const get_countries = () => async (dispatch) => {
    try {
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.countries()}`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                dispatch(set_countries(data));
            }
        }
    } catch (error) {
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const charge = (data) => async (dispatch) => {
    try {
        dispatch(set_loading({type: "vendors", value: true}));
        const formData = new FormData();
        formData.append("id", data.id);
        formData.append("cost", data.cost);
        const response = await fetchingDataWithAxiosMiddleware(
            "PUT",
            `${apiService.charge()}`,
            formData
        );
        if (response?.status) {
            dispatch(get_vendors());
            dispatch(set_response_notification({type: "success", text: "Success"}));
        }
    } catch (error) {
        if (error.message === "Network Error") {
            dispatch(setServerIsDown(true));
        }
        const message = error?.response ? error?.response : error.message;
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + message,
            })
        );
        dispatch(set_loading({type: "vendors", value: false}));
    }
};
export const get_requests = (query) => async (dispatch) => {
    try {
        dispatch(set_loading({type: "requests", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.requests()}${query ? query : ""}`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                dispatch(set_requests(changeResponseDate(data, "requests")));
                dispatch(set_loading({type: "requests", value: false}));
            }
        }
    } catch (error) {
        if (error.message === "Network Error") {
            dispatch(setServerIsDown(true));
        }
        dispatch(set_loading({type: "requests", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};

export const getBackendError = (query) => async (dispatch) => {
    try {
        dispatch(set_loading({type: "backendErrors", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.backendErrors()}${query ? query : ""}`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                dispatch(set_backendErrors(changeResponseDate(data, "backendErrors")));
                dispatch(set_loading({type: "backendErrors", value: false}));
            }
        }
    } catch (error) {
        dispatch(set_loading({type: "backendErrors", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const getDebuggingInfo = () => async (dispatch) => {
    try {
        dispatch(set_loading({type: "debuggingInfo", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.debuggingHistory()}/history`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                dispatch(setDebuggingInfo(data));
                // dispatch(set_requests(changeResponseDate(data, "requests")));
                dispatch(set_loading({type: "debuggingInfo", value: false}));
            }
        }
    } catch (error) {
        if (error.message === "Network Error") {
            dispatch(setServerIsDown(true));
        }
        dispatch(set_loading({type: "debuggingInfo", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const startProcessingForDebugging = () => async (dispatch) => {
    try {
        dispatch(set_loading({type: "debuggingInfo", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.debuggingHistory()}/execute-all`
        );
        if (response.status) {
            dispatch(getDebuggingInfo());
            dispatch(
                set_response_notification({
                    type: "success",
                    text: "Success",
                })
            );
        }
    } catch (error) {
        dispatch(set_loading({type: "debuggingInfo", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const getTestingMode = () => async (dispatch) => {
    try {
        dispatch(set_loading({type: "testingMode", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.testingMode()}`
        );
        if (response.status) {
            const data = await response.data;
            dispatch(setTestingMode(data));
            dispatch(set_loading({type: "testingMode", value: false}));
        }
    } catch (error) {
        if (error.message === "Network Error") {
            dispatch(setServerIsDown(true));
        }
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
        dispatch(set_loading({type: "testingMode", value: false}));
    }
};
export const editTestingMode = (mode) => async (dispatch) => {
    try {
        dispatch(set_loading({type: "testingMode", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "PUT",
            `${apiService.testingMode()}?mode=${mode}`
        );
        if (response.status) {
            dispatch(getTestingMode());
        } else {
        }
    } catch (error) {
        if (error.message === "Network Error") {
            dispatch(setServerIsDown(true));
        }
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
        dispatch(set_loading({type: "testingMode", value: false}));
    }
};

export const restartServer = () => async (dispatch) => {
    try {
        const response = await fetchingDataWithAxiosMiddleware(
            "POST",
            `${apiService.restartServer()}`
        );
        if (response.status) {
            dispatch(setServerIsDown(true));
            dispatch(set_response_notification({type: "success", text: "Success"}));
        }
    } catch (error) {
        if (error.message === "Network Error") {
            dispatch(setServerIsDown(true));
        }
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
